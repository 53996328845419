.divClass {
  margin-top: 16px;
  font-size: 14px;
  color: #333;
}

.divContent {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
}

.contentClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.oneDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-top: 20px;
  font-size: 12px;
  color: #666666;
}