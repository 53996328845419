.listDivStyle {
  background: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.listDivStyle:last-child {
  margin-bottom: 0;
}
.purchase {
  flex: 1;
  padding: 14px;
  text-align: center;
  color: #666666;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  /* 关键在这里，设置切线的角度和长度 */
  background: linear-gradient(-45deg, transparent 30px, rgb(247, 247, 247) 0);
  /* 设置之后需要把位置向相反的方向偏移，达到粘合 */
  margin-right: -22px;
}

.recharge {
  flex: 1;
  padding: 14px;
  text-align: center;
  color: #fff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: linear-gradient(135deg, transparent 30px, rgb(0, 170, 255) 0);
  margin-left: -20px;
}

.hearderStyle {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  margin-right: 15px;
}

.buttonType {
  width: 48px;
  height: 24px;
  background: #E5F6FF;
  border: 1px solid #00AAFF;
  border-radius: 12px;
  line-height: 24px;
  color: #00AAFF;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
}