.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.infoList .am-list-item, .infoList .am-list-item.am-input-item{
  margin: 0 10px;
  height: 44px;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 0;
}
.infoList .am-list-item:last-child{
  border-bottom: 0;
}
.infoList .am-list-body .am-list-item .am-list-line .am-list-content, .infoList .am-list-item .am-input-label {
  font-size: 14px;
  padding: 0;
  flex: none;
  color: #333333;
  margin-right: 10px;
}

.infoList .am-list-item .am-list-line .am-list-extra, .infoList .am-list-item .am-input-control input{
  flex: 1;
  font-size: 14px;
  color: #333333;
  line-height: inherit;
}

.infoList .am-list-item .am-list-line {
  padding-right: 0;
  align-self: auto;
}

html .infoList .am-list-body div:not(:last-child) .am-list-line::after {
  content: '';
  height: 0;
}

.ownerList .am-list-item, .ownerList .am-list-item.am-input-item {
  border-bottom: 0;
}

html:not([data-scale]) .am-list-body::before,html:not([data-scale]) .am-list-body::after{
  content: '';
  height: 0;
}
.ownerList .am-list-body {
  border-radius: 6px;
}
.ownerList .am-list-item .am-input-label.am-input-label-5 {
  width: auto;
}
.ownerList .am-list-body .am-list-item .am-list-line .am-list-content, .ownerList .am-list-item .am-input-label {
  margin-right: 25px;
}
.ownerList .am-list-item img {
  width: 40px;
  height: 40px;
}
.changeColorPicker {
  color: '#666666'
}

.houseList .am-list-body {
  background-color: #F5F5F9;
}

.listV5 .adm-list-inner .adm-list-item {
  padding-left: 0;
}

.listV5 .adm-list-inner .adm-list-item .adm-list-item-content {
  border: 0;
}

.listV5 .adm-list-item-content {
  border-bottom: 0;
}
.listV5 .adm-list-default .adm-list-body {
  border-bottom: 0;
}
.list_v5_v2 .adm-list-body {
  background-color: transparent;
}
.adm-steps-vertical {
  padding: 0;
  --title-font-size: 14px;
}
.adm-steps-vertical .adm-step-status-process .adm-step-title {
  color: #333333;
}

.payStatis .adm-progress-circle-content {
  width: 90px !important;
  height: 90px !important;
}
.collapseNoneBorder > .adm-list-default, .collapseNoneBorder .adm-list-default .adm-list-body, .myCard .adm-list-default .adm-list-body {
  border: 0;
}
.collapseNoneBorder .adm-list-item-content {
  padding: 0;
  border-bottom: 0;
}
.rightInput input{
  text-align: right;
}
.myForm .adm-list-body {
  border-radius: 10px;
}