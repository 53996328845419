.contentTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  background: #FFFFFF;
  padding: 0 15px;
  margin-bottom: 15px;
}

.contentTopRight {
  display: flex;
}
.contentTopRightTwo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
  padding: 10px 0;
}
.buttonWeChat {
  width: 75px;
  height: 34px;
  background: #4873C0;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 34px;
}
.bottomDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 15px;
  color: #4873C0;
  font-size: 14px;
}
