.bodyDiv {
  background: #fff;
  padding: 10px 15px 0;
  overflow-y: auto;
  height: calc(100vh - 37px);
}

.bodyList {
  font-size: 14px;
  border: 0;
}

.buttonDivSty {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.popupStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-Bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}