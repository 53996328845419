.cardBackground {
  background-image: url('../../images/card.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding: 0 17px;
  justify-content: space-between;
}

.cardBackground:last-child {
  margin-bottom: 15px;
}

.infoButton {
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 34px;
  width: 80px;
  height: 34px;
  background: #00AAFF;
  border-radius: 17px;
}

.divBox {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.divBoxTop {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.divBoxBottom {
  font-size: 12px;
  color: #666666;
  margin-bottom: 20px;
  text-align: right;
}

.divBoxStatus {
  color: #00AAFF;
  font-size: 14px;
  text-align: right;
}