
.myDivStyle {
  background: #fff;
  padding: 0 15px;
  font-size: 14px;
  color: #333;
}
.topDivStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}