.paymentBackground {
  background-image: url('../../images/card.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100px;
  margin-top: 15px;
  display: flex;
  padding: 0 17px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
}

.divBoxButton {
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 34px;
  width: 80px;
  height: 34px;
  background: #00AAFF;
  border-radius: 17px;
}

.paymentContent {
  margin-left: 35px;
  font-size: 12px;
  color: #666666;
  margin-bottom:14px;
  
}